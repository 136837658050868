<template>
  <div>
    <b-card>
      <b-card-title class="mb-0">
        <slot name="name" />
      </b-card-title>
    </b-card>
    <slot name="main" />
  </div>
</template>

<script>
import {
  BCard,
  BCardTitle,
} from 'bootstrap-vue'

export default {
  name: 'AddOnsSettings',
  components: {
    BCard,
    BCardTitle,
  },
}
</script>
