<template>
  <b-overlay
    :show="isLoading"
    spinner-variant="primary"
  >
    <b-card>
      <div class="d-flex justify-content-between align-items-center mb-2">
        <b-card-title class="mb-0">
          {{ $t('addOnsPaymentsName') }}
        </b-card-title>
        <b-badge
          v-if="!isLoading"
          :variant="isEnabled ? 'light-success' : 'light-danger'"
        >
          {{ isEnabled ? $t('addOnsAvailableTitle') : $t('addOnsNotAvailableTitle') }}
        </b-badge>
      </div>
      <p :class="['font-small-3 line-height-inherit', { 'mb-0': isLoading }]">
        {{ $t('addOnsPaymentsDescription') }}
      </p>
      <template v-if="!isLoading">
        <template v-if="isEnabled">
          <div class="d-flex justify-content-between align-items-center mt-2 mb-1">
            <div class="font-weight-bold">
              {{ $t('addOnsPaymentsActivateName') }}
            </div>
            <b-form-checkbox
              v-model="isActive"
              :disabled="isDisabled"
              switch
              inline
              class="mr-0"
            />
          </div>
          <p class="font-small-3 line-height-inherit">
            {{ $t('addOnsSaveText') }}
          </p>
          <b-button
            variant="primary"
            :disabled="isDisabled"
            @click="onClickSave"
          >
            <b-spinner
              v-if="isSaving"
              class="d-flex"
              small
            />
            <template v-else>
              {{ $t('addOnsSaveName') }}
            </template>
          </b-button>
          <hr class="mt-2 mb-2">
          <div class="d-flex align-items-center justify-content-between mb-2">
            <b-button
              v-if="!userHasAccount || !canReceivePayments"
              variant="primary"
              :disabled="isDisabled"
              @click="onClickLinkAccount"
            >
              <b-spinner
                v-if="isLinkingAccount"
                class="d-flex"
                small
              />
              <template v-else>
                {{ !userHasAccount ? $t('addOnsPaymentsLinkAccount') : $t('addOnsPaymentsLinkAccountContinue') }}
              </template>
            </b-button>
            <b-button
              v-else
              variant="outline-primary"
              :disabled="isDisabled"
              @click="onClickUnlinkAccount"
            >
              <b-spinner
                v-if="isUnlinkingAccount"
                class="d-flex"
                small
              />
              <template v-else>
                {{ $t('addOnsPaymentsUnlinkAccount') }}
              </template>
            </b-button>
            <b-badge
              v-if="userHasAccount && !canReceivePayments"
              variant="light-danger"
            >
              {{ $t('addOnsPaymentsCantReceive') }}
            </b-badge>
          </div>
          <b-form-group
            v-if="surcharges.length"
            :label="$t('addOnsPaymentsSurchargesListName')"
            class="mb-0"
          >
            <b-table
              :responsive="true"
              :outlined="true"
              :items="surcharges"
              :fields="surchargesFields"
            >
              <template #cell(name)="row">
                <div :class="[!userHasAccount ? 'text-muted' : null]">
                  {{ row.item.name }}
                </div>
              </template>
              <template #cell(value)="row">
                <div :class="[!userHasAccount ? 'text-muted' : null]">
                  <template v-if="row.item.type === 'ADDITION'">
                    <currency-symbol>{{ getFormattedPrice(row.item.value) }}</currency-symbol>
                  </template>
                  <template v-else-if="row.item.type === 'PERCENTAGE' || row.item.type === 'PERCENTAGE_DISCOUNT'">
                    {{ row.item.value }}%
                  </template>
                  <template v-else>
                    {{ row.item.value }}
                  </template>
                </div>
              </template>
              <template #cell(delete)="row">
                <div class="d-flex justify-content-end">
                  <b-spinner
                    v-if="deletingSurchargeId === row.item.id"
                    variant="primary"
                    small
                  />
                  <feather-icon
                    v-else
                    :class="[
                      { 'text-danger': !isDisabled },
                      { 'feather-disabled': isDisabled },
                    ]"
                    icon="TrashIcon"
                    size="18"
                    role="button"
                    @click="onClickDeleteSurcharge(row.item)"
                  />
                </div>
              </template>
            </b-table>
            <!--<div
              v-if="surcharges.find(i => i.type === 'PERCENTAGE' || i.type === 'PERCENTAGE_DISCOUNT')"
              class="mt-1"
            >
              <b-form-checkbox
                :checked="isTypePercentageDiscount"
                class="custom-control-primary"
                :disabled="isDisabled"
                @input="onInputTypePercentageDiscount"
              >
                {{ $t('addOnsPaymentsTypePercentageDiscountName') }}
              </b-form-checkbox>
            </div>-->
          </b-form-group>
          <validation-observer ref="vo">
            <b-row>
              <b-col>
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('addOnsPaymentsSurchargeNameLabel')"
                    class="mb-0"
                  >
                    <b-form-input
                      v-model="newSurcharge.name"
                      :disabled="isDisabled"
                      :state="errors.length ? false : null"
                      :placeholder="$t('addOnsPaymentsSurchargeNamePlaceholder')"
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                cols="auto"
                class="d-flex"
              >
                <validation-provider
                  #default="{ errors }"
                  :rules="`required|positive|integer_double${newSurcharge.type === 'PERCENTAGE' ? '|max-value:100' : ''}`"
                  class="d-flex flex-column"
                >
                  <b-form-group
                    :label="$t('addOnsPaymentsSurchargeValueLabel')"
                    class="mb-0"
                  >
                    <b-form-input
                      v-model="newSurcharge.value"
                      placeholder="10"
                      class="mr-1"
                      :disabled="isDisabled"
                      :state="errors.length ? false : null"
                      style="max-width: 130px"
                    />
                  </b-form-group>
                </validation-provider>
                <b-form-group
                  :label="$t('addOnsPaymentsSurchargeTypeLabel')"
                  class="mb-0"
                >
                  <b-form-radio-group
                    v-model="newSurcharge.type"
                    :options="newSurchargeTypes"
                    :disabled="isDisabled"
                    class="pt-50"
                  />
                </b-form-group>
              </b-col>
              <b-col
                cols="auto"
                class="pt-2"
              >
                <b-button
                  variant="outline-primary"
                  class="btn-icon mt-50"
                  :disabled="isDisabled"
                  @click="onClickAddSurcharge"
                >
                  <b-spinner
                    v-if="isSurchargesSaving"
                    class="d-flex"
                    small
                  />
                  <feather-icon
                    v-else
                    icon="PlusIcon"
                  />
                </b-button>
              </b-col>
            </b-row>
          </validation-observer>
        </template>
        <template v-else>
          <hr>
          <i18n
            path="addOnsNotAvailableText"
            tag="p"
            class="font-small-3 line-height-inherit mb-0"
          >
            <template v-slot:slot>
              <b>{{ $t('addOnsPaymentsName') }}</b>
            </template>
            <template v-slot:slot2>
              <router-link :to="{ name: 'add-ons' }">
                {{ $t('addOnsFullName') }}
              </router-link>
            </template>
          </i18n>
        </template>
      </template>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BCardTitle,
  BBadge,
  BFormCheckbox,
  BButton,
  BSpinner,
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormRadioGroup,
  BTable,
} from 'bootstrap-vue'
import { v4 as uuidv4 } from 'uuid'
import { mapGetters, mapActions } from 'vuex'
import CurrencySymbol from '@/components/CurrencySymbol.vue'
import mixinFormatter from '@/mixins/formatter'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  required,
  integerDouble,
  positive,
  maxValue,
} from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import UPDATE_RESTAURANT from '@/gql/mutation/restaurant/updateRestaurant.gql'
import VALIDATE_ABILITY_TO_RECEIVE_PAYMENTS from '@/gql/query/validateAbilityToReceivePayments.gql'
import CREATE_PAYMENTS_ACCOUNT_FOR_RESTAURANT from '@/gql/mutation/payment/createPaymentsAccountForRestaurant.gql'
import DELETE_PAYMENTS_ACCOUNT_FOR_RESTAURANT from '@/gql/mutation/payment/deletePaymentsAccountForRestaurant.gql'

export default {
  name: 'AddOnsSettingsPayments',
  components: {
    BOverlay,
    BCard,
    BCardTitle,
    BBadge,
    BFormCheckbox,
    BButton,
    BSpinner,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormRadioGroup,
    BTable,
    CurrencySymbol,
    ValidationObserver,
    ValidationProvider,
  },
  mixins: [
    mixinFormatter,
  ],
  data() {
    return {
      isLoading: true,
      isActive: false,
      isSaving: false,

      canReceivePayments: false,
      onboardingLink: '',
      userHasAccount: false,

      isUnlinkingAccount: false,
      isLinkingAccount: false,

      isSurchargesSaving: false,
      deletingSurchargeId: null,
      surcharges: [],
      isTypePercentageDiscount: false,
      newSurcharge: {
        name: '',
        type: 'PERCENTAGE',
        value: '',
      },

      required,
      positive,
      integerDouble,
      maxValue,
    }
  },
  computed: {
    ...mapGetters({
      restaurant: 'restaurant/restaurant',
      subscription: 'billing/subscription',
    }),
    isEnabled() {
      const name = 'Payments'
      return !!(
        this.subscription
        && this.subscription.addons.length
        && this.subscription.addons.find(i => i.name === name)
        && this.subscription.addons.find(i => i.name === name).enabled
      )
    },
    newSurchargeTypes() {
      return [
        { text: this.$t('addOnsPaymentsSurchargeTypeAddition'), value: 'ADDITION' },
        { text: this.$t('addOnsPaymentsSurchargeTypePercentage'), value: 'PERCENTAGE' },
      ]
    },
    surchargesFields() {
      return [
        { key: 'name', label: this.$t('addOnsPaymentsSurchargeNameLabel') },
        { key: 'value', label: this.$t('addOnsPaymentsSurchargeValueLabel') },
        { key: 'delete', label: '' },
      ]
    },
    isDisabled() {
      return this.isSaving
        || this.isSurchargesSaving
        || this.isLinkingAccount
        || this.isUnlinkingAccount
        || this.deletingSurchargeId
    },
  },
  watch: {
    subscription() {
      this.setData()
    },
  },
  created() {
    this.setData()
  },
  methods: {
    ...mapActions({
      updateRestaurant: 'restaurant/updateRestaurant',
    }),
    // onInputTypePercentageDiscount(eventValue) {
    //   this.isTypePercentageDiscount = eventValue
    //
    //   this.surcharges = this.surcharges.map(i => {
    //     if (i.type === 'PERCENTAGE' || i.type === 'PERCENTAGE_DISCOUNT') {
    //       return {
    //         ...i,
    //         type: eventValue ? 'PERCENTAGE_DISCOUNT' : 'PERCENTAGE',
    //       }
    //     }
    //     return i
    //   })
    // },
    async onClickDeleteSurcharge(surcharge) {
      this.deletingSurchargeId = surcharge.id

      const surcharges = this.surcharges
        .filter(i => i.id !== this.deletingSurchargeId)
        .map(i => ({
          name: i.name,
          type: i.type,
          value: +i.value,
        }))

      try {
        await this.$apollo.mutate({
          mutation: UPDATE_RESTAURANT,
          variables: {
            updateRestaurantId: this.restaurant.id,
            surcharges,
          },
        })

        this.updateRestaurant({ surcharges })

        this.surcharges = this.surcharges.filter(i => i.id !== surcharge.id)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Success'),
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: error.graphQLErrors[0].id,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.deletingSurchargeId = null
      }
    },
    async onClickAddSurcharge() {
      const isValid = await this.$refs.vo.validate()
      if (!isValid) {
        return
      }

      this.isSurchargesSaving = true

      const surcharge = {
        ...this.newSurcharge,
        // eslint-disable-next-line no-nested-ternary
        type: this.newSurcharge.type === 'PERCENTAGE'
          ? this.isTypePercentageDiscount
            ? 'PERCENTAGE_DISCOUNT'
            : this.newSurcharge.type
          : this.newSurcharge.type,
        id: uuidv4(),
      }
      const surcharges = [...this.surcharges, surcharge].map(i => ({
        name: i.name,
        type: i.type,
        value: +i.value,
      }))

      try {
        await this.$apollo.mutate({
          mutation: UPDATE_RESTAURANT,
          variables: {
            updateRestaurantId: this.restaurant.id,
            surcharges,
          },
        })

        this.updateRestaurant({ surcharges })

        this.surcharges = [...this.surcharges, surcharge]
        this.newSurcharge = { ...this.newSurcharge, name: '', value: '' }
        this.$refs.vo.reset()

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Success'),
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: error.graphQLErrors[0].id,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isSurchargesSaving = false
      }
    },
    async setData() {
      if (!this.subscription) {
        return
      }

      if (this.isEnabled) {
        await this.validateAbilityToReceivePayments()
      }

      this.isActive = this.restaurant.paymentsSettings.enabled

      if (this.restaurant.surcharges) {
        this.surcharges = this.restaurant.surcharges.map(i => ({ ...i, id: uuidv4() }))

        // const surchargesPercentage = this.surcharges
        //   .filter(i => i.type === 'PERCENTAGE' || i.type === 'PERCENTAGE_DISCOUNT')
        // this.isTypePercentageDiscount = !!(
        //   surchargesPercentage.length && surchargesPercentage.every(i => i.type === 'PERCENTAGE_DISCOUNT')
        // )
      }

      this.isLoading = false
    },
    async validateAbilityToReceivePayments() {
      const response = await this.$apollo.query({
        query: VALIDATE_ABILITY_TO_RECEIVE_PAYMENTS,
        variables: {
          restaurantId: this.restaurant.id,
        },
      })

      const { canReceivePayments, onboardingLink, userHasAccount } = response.data.validateAbilityToReceivePayments

      this.canReceivePayments = canReceivePayments
      this.onboardingLink = onboardingLink
      this.userHasAccount = userHasAccount
    },
    onClickSave() {
      this.saveSettings()
    },
    async saveSettings() {
      this.isSaving = true

      const paymentsSettings = {
        ...this.restaurant.paymentsSettings,
        enabled: this.isActive,
      }

      try {
        await this.$apollo.mutate({
          mutation: UPDATE_RESTAURANT,
          variables: {
            updateRestaurantId: this.restaurant.id,
            paymentsSettings,
          },
        })

        this.updateRestaurant({ paymentsSettings })

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Success'),
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: error.graphQLErrors[0].id,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isSaving = false
      }
    },
    async onClickLinkAccount() {
      this.isLinkingAccount = true

      try {
        if (!this.userHasAccount) {
          const response = await this.$apollo.mutate({
            mutation: CREATE_PAYMENTS_ACCOUNT_FOR_RESTAURANT,
            variables: {
              restaurantId: this.restaurant.id,
            },
          })

          this.onboardingLink = response.data.createPaymentsAccountForRestaurant
        }

        window.open(this.onboardingLink, '_self')
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: error.graphQLErrors[0].id,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLinkingAccount = false
      }
    },
    async onClickUnlinkAccount() {
      this.isUnlinkingAccount = true

      try {
        await this.$apollo.mutate({
          mutation: DELETE_PAYMENTS_ACCOUNT_FOR_RESTAURANT,
          variables: {
            restaurantId: this.restaurant.id,
          },
        })

        this.userHasAccount = false

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Success'),
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: error.graphQLErrors[0].id,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isUnlinkingAccount = false
      }
    },
  },
}
</script>
