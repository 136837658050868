<template>
  <addons-integrations-settings>
    <template v-slot:name>
      {{ $t('addOnsSettingsTitle') }}
    </template>
    <template v-slot:main>
      <add-ons-settings-events />
      <add-ons-settings-orders />
      <add-ons-settings-payments v-if="isUserSporkMember" />
    </template>
  </addons-integrations-settings>
</template>

<script>
import AddonsIntegrationsSettings from '@/components/AddonsIntegrationsSettings.vue'
import AddOnsSettingsEvents from '@/components/addons-settings/AddOnsSettingsEvents.vue'
import AddOnsSettingsOrders from '@/components/addons-settings/AddOnsSettingsOrders.vue'
import AddOnsSettingsPayments from '@/components/addons-settings/AddOnsSettingsPayments.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'AddOnsSettings',
  components: {
    AddonsIntegrationsSettings,
    AddOnsSettingsEvents,
    AddOnsSettingsOrders,
    AddOnsSettingsPayments,
  },
  computed: {
    ...mapGetters({
      isUserSporkMember: 'user/isUserSporkMember',
    }),
  },
}
</script>
