<template>
  <b-overlay
    :show="isLoading"
    spinner-variant="primary"
  >
    <b-card>
      <div class="d-flex justify-content-between align-items-center mb-2">
        <b-card-title class="mb-0">
          {{ $t('addOnsOrdersName') }}
        </b-card-title>
        <b-badge
          v-if="!isLoading"
          :variant="isEnabled ? 'light-success' : 'light-danger'"
        >
          {{ isEnabled ? $t('addOnsAvailableTitle') : $t('addOnsNotAvailableTitle') }}
        </b-badge>
      </div>
      <p :class="['font-small-3 line-height-inherit', { 'mb-0': isLoading }]">
        {{ $t('addOnsOrdersDescription') }}
      </p>
      <template v-if="!isLoading">
        <template v-if="isEnabled">
          <div class="d-flex justify-content-between align-items-center mt-2 mb-1">
            <div class="font-weight-bold">
              {{ $t('addOnsOrdersActivateName') }}
            </div>
            <b-form-checkbox
              v-model="isActive"
              :disabled="isSaving"
              switch
              inline
              class="mr-0"
            />
          </div>
          <p class="font-small-3 line-height-inherit">
            {{ $t('addOnsSaveText') }}
          </p>
          <b-button
            variant="primary"
            :disabled="isSaving"
            @click="onClickSave"
          >
            <b-spinner
              v-if="isSaving"
              class="d-flex"
              small
            />
            <template v-else>
              {{ $t('addOnsSaveName') }}
            </template>
          </b-button>
        </template>
        <template v-else>
          <hr>
          <i18n
            path="addOnsNotAvailableText"
            tag="p"
            class="font-small-3 line-height-inherit mb-0"
          >
            <template v-slot:slot>
              <b>{{ $t('addOnsOrdersName') }}</b>
            </template>
            <template v-slot:slot2>
              <router-link :to="{ name: 'add-ons' }">
                {{ $t('addOnsFullName') }}
              </router-link>
            </template>
          </i18n>
        </template>
      </template>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BCard,
  BCardTitle,
  BBadge,
  BFormCheckbox,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { mapGetters, mapActions } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import UPDATE_RESTAURANT from '@/gql/mutation/restaurant/updateRestaurant.gql'

export default {
  name: 'AddOnsSettingsOrders',
  components: {
    BOverlay,
    BCard,
    BCardTitle,
    BBadge,
    BFormCheckbox,
    BButton,
    BSpinner,
  },
  data() {
    return {
      isLoading: true,
      isActive: false,
      isSaving: false,
    }
  },
  computed: {
    ...mapGetters({
      restaurant: 'restaurant/restaurant',
      subscription: 'billing/subscription',
    }),
    isEnabled() {
      const name = 'Orders'
      return !!(
        this.subscription
        && this.subscription.addons.length
        && this.subscription.addons.find(i => i.name === name)
        && this.subscription.addons.find(i => i.name === name).enabled
      )
    },
  },
  watch: {
    subscription() {
      this.setData()
    },
  },
  created() {
    this.setData()
  },
  methods: {
    ...mapActions({
      updateRestaurant: 'restaurant/updateRestaurant',
    }),
    setData() {
      if (!this.subscription) {
        return
      }

      this.isActive = this.restaurant.ordersSettings.enabled

      this.isLoading = false
    },
    onClickSave() {
      this.saveSettings()
    },
    async saveSettings() {
      this.isSaving = true

      const ordersSettings = {
        ...this.restaurant.ordersSettings,
        enabled: this.isActive,
      }

      try {
        await this.$apollo.mutate({
          mutation: UPDATE_RESTAURANT,
          variables: {
            updateRestaurantId: this.restaurant.id,
            ordersSettings,
          },
        })

        this.updateRestaurant({ ordersSettings })

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Success'),
            icon: 'CheckCircleIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('Error'),
            text: error.graphQLErrors[0].id,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isSaving = false
      }
    },
  },
}
</script>
